import {
  BanknotesIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

import { Link, useParams } from "@remix-run/react";
import moment from "moment-timezone";
import React from "react";
import { MindspaceEventCardFragment } from "~/graphql/__generated";

export default function MindspaceEventTimes({
  event,
}: {
  event: MindspaceEventCardFragment;
}) {
  const timeZone = moment.tz.guess();
  const startDatetime = moment(event.startDatetime).tz(timeZone);
  const endDatetime = moment(event.endDatetime).tz(timeZone);

  return (
    <>
      {startDatetime.format("HH:mm")} - {endDatetime.format("HH:mm z")}
    </>
  );
}
